const { TrueorFalse } = require("../dataValidations")

function EngagementValidation (rows) {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^hierarchyId$/.test(row[0])){
    return "Column 1 heading should be hierarchy Id"
  } else if (!row[1] || !/^defaultLanguage$/.test(row[1])){
    return "Column 2 heading should be defaultLanguage"
  } else if (!row[2] || !/^doseTimings$/.test(row[2])){
    return "Column 3 heading should be doseTimings"
  } else if (!row[3] || !/^defaultTime$/.test(row[3])){
    return "Column 4 heading should be defaultTime"
  } else if (!row[4] || !/^languages$/.test(row[4])){
    return "Column 5 heading should be languages"
  } else if (!row[5] || !/^consentMandatory$/.test(row[5])){
    return "Column 6 heading should be consentMandatory"
  } else if (!row[6] || !/^notificationType$/.test(row[6])){
    return "Column 7 heading should be notificationType"
  } else if (!row[7] || !/^disabledLanguages$/.test(row[7])){
    return "Column 8 heading should be disabledLanguages"
  }
}
function validateCsvRow (row) {
  if (
    /[,]/g.test(row[0]) ||
      /[,]/g.test(row[1])
  ) {
    return "Remove every single comma ',' and try again"
  } else if (!row[0] || !/^[0-9]+$/.test(row[0])) {
    return `Column 1 should have a valid hierarchyId`
  } else if (!row[1] || !/^[0-9]*$/.test(row[1])) {
    return `Column 2 should have a valid defaultLanguage`
  } else if (!row[2] || !/^[0-9:,]*$/.test(row[2])) {
    return `Column 3 should have a valid doseTimings`
  } else if (!row[3] || !/^[0-9:]*$/.test(row[3])) {
    return `Column 4 should have a valid defaultTime`
  } else if (!row[4] || !/^[0-9,]*$/.test(row[4])) {
    return `Column 5 should have a valid languages`
  } else if (!row[5] || !TrueorFalse(row[5])) {
    return `Column 6 should have a valid consentMandatory`
  } else if (!row[6] || !/^[0-9]*$/.test(row[6])) {
    return `Column 7 should have a valid notificationType`
  } else if (!/^$/gm.test(row[7]) || !/^[0-9,]*$/.test(row[7])) {
    return `Column 8 should have a valid disabledLanguages`
  }
}
module.exports = EngagementValidation
